import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { contentSummaryContent$key } from "scmp-app/queries/__generated__/contentSummaryContent.graphql";

import { ContentSummaryContainer } from "./styles";

export type Props = {
  className?: string;
  isTextOverflowEllipsis?: boolean;
  reference: contentSummaryContent$key;
};

export const ContentSummary: FunctionComponent<Props> = ({
  isTextOverflowEllipsis,
  reference: reference_,
  ...attributes
}) => {
  const content = useFragment(
    graphql`
      fragment contentSummaryContent on Content {
        __typename
        summary {
          text
        }
      }
    `,
    reference_,
  );

  const isVideo = content?.__typename === "Video";

  const isEnableTextOverflowEllipsis = (isTextOverflowEllipsis && isVideo) ?? false;

  if (!content.summary?.text?.trim()) return null;

  return (
    <ContentSummaryContainer
      $isEnableTextOverflowEllipsis={isEnableTextOverflowEllipsis}
      {...attributes}
    >
      {content.summary?.text ?? ""}
    </ContentSummaryContainer>
  );
};

ContentSummary.displayName = "ContentSummary";
