import styled from "@emotion/styled";
import { transientOptions } from "@product/scmp-sdk";

import { AuthorAvatar } from "scmp-app/components/author/author-avatar";
import { EntityLink } from "scmp-app/components/entity-link";

export const AuthorEntityLink = styled(EntityLink)``;

type AuthorAvatarProps = {
  $zIndex: number;
};
export const StyledAuthorAvatar = styled(AuthorAvatar, { ...transientOptions })<AuthorAvatarProps>`
  position: relative;
  z-index: ${props => props.$zIndex};

  display: inline-block;

  inline-size: 40px;
  block-size: 40px;
  box-sizing: content-box;
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;

  margin-block-end: 8px;

  ${StyledAuthorAvatar}:not(:first-child) {
    margin-block-start: -8px;
  }

  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    flex-direction: row;

    margin-block-end: 0;
    margin-inline-end: 8px;

    ${StyledAuthorAvatar}:not(:first-child) {
      margin-block-start: -0;
      margin-inline-start: -8px;
    }
  }
`;
