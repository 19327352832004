import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { EntityLink } from "scmp-app/components/entity-link";

type SectionContainerProps = {
  $hide?: boolean;
};

export const SectionContainer = styled.div<SectionContainerProps>`
  ${props =>
    props.$hide &&
    css`
      display: none;
    `}
`;

export const SectionLink = styled(EntityLink)`
  color: #4585ff;
  font-weight: 700;
  font-size: 13px;
  font-family: ${fontRobotoCondensed};
  text-transform: uppercase;
`;
