/**
 * @generated SignedSource<<7671e70c1dfdf8543ec864c60894bc68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentSummaryContent$data = {
  readonly __typename: string;
  readonly summary: {
    readonly text: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "contentSummaryContent";
};
export type contentSummaryContent$key = {
  readonly " $data"?: contentSummaryContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentSummaryContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentSummaryContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FormattedValue",
      "kind": "LinkedField",
      "name": "summary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "0f4b22cca9dfdf5f20ca78d26558702b";

export default node;
