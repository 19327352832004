import styled from "@emotion/styled";

type StyledProps = {
  $isEnableTextOverflowEllipsis: boolean;
};

export const ContentSummaryContainer = styled.p<StyledProps>`
  word-break: break-word;

  ${props =>
    props.$isEnableTextOverflowEllipsis
      ? `
        display: -webkit-box;

        text-overflow: ellipsis;

        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-clamp: 3;
      `
      : null}
`;
