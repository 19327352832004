import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { transientOptions } from "@product/scmp-sdk";

import { ArticleComment } from "scmp-app/components/article/article-comment";
import {
  StyledCommentFilledIcon,
  StyledCommentIcon,
} from "scmp-app/components/article/article-comment/styles";
import { Icon } from "scmp-app/components/common/icon";
import { ContentBookmark } from "scmp-app/components/content/content-bookmark";

export const FlexContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  inline-size: 100%;
`;

export const VideoIcon = styled(Icon)`
  margin-inline-start: 10px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: stretch;

  & + & {
    margin-inline-start: 20px;
  }
`;

export const StyledContentBookmark = styled(ContentBookmark)`
  inline-size: 15px;
  block-size: 15px;
  margin-inline-start: 16px;
  padding: 0;

  color: #4585ff;
`;

export type StyledArticleCommentProps = {
  $variant?: "reverse" | "default";
};
export const StyledArticleComment = styled(ArticleComment, {
  ...transientOptions,
})<StyledArticleCommentProps>`
  ${props => {
    switch (props.$variant) {
      case "reverse":
        return css`
          flex-direction: row-reverse;

          ${StyledCommentFilledIcon}, ${StyledCommentIcon} {
            margin-inline-start: 0;
            margin-inline-end: 8px;
          }
        `;
      default:
        return null;
    }
  }}
`;
